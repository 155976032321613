import { createSharedComposable } from '@vueuse/core';
import { EstimateContentType } from '~/types/enums';
import type { Quote, WorkItem } from '~/types/interfaces';
import { useStorage } from "@vueuse/core";
import type { Product } from "#graphitoolbox/types/cart";

const _useQuote = () => {

	type PromoType = 'before' | 'after'

	const quote = useStorage<Quote>("quote", <Quote>{}, sessionStorage)

	/**
	 * Reset the current quote in session storage
	 * @returns {void}
	 */
	const resetQuote = (): void => {
		//@ts-ignore
		quote.value = {articles:[]}
	}

	/**
	 * Calculates the total cost before or after discount for the given articles.
	 * @param {Product[]} articles - Array of product objects.
	 * @param {boolean} includeDiscount - Flag indicating whether to include discount in the calculation.
	 * @returns {number} The calculated total cost.
	 */
	const calculateTotalWorkHt = (articles?: Product[], includeDiscount?: boolean): number => {
		if(!articles) return 0

		return articles.reduce((total, product) => {

			const worksTotal = (product.works || []).reduce((workTotal, work) => {
				const calculatedTime = work.calculatedTime ? parseFloat(work.calculatedTime.toString()) : 0
				return workTotal + (calculatedTime * (work.rate?.value ?? 1))
			}, 0)
			
			const variousTimesTotal = product.productTypeQuoteId === EstimateContentType.VariousTimes
				? product.price.unitPriceBeforePromoHT * product.quantity * (includeDiscount ? (1 - product.price.discount / 100) : 1)
				: 0

			const laborTimeTotal = product.productTypeQuoteId === EstimateContentType.LaborTime && product.rate
				? product.rate.value * product.quantity * (includeDiscount ? (1 - product.price.discount / 100) : 1)
				: 0

			return total + worksTotal + variousTimesTotal + laborTimeTotal
		}, 0)
	}

	/**
	 * Calculates the total time from works and quantities of products.
	 * @param {Product[]} articles - Array of product objects.
	 * @returns {number} The calculated total time.
	 */
	const calculateTotalTime = (articles?: Product[]): number => {
		if(!articles) return 0

		return articles.reduce((total: number, product: Product) => {
			const worksTimeTotal = (product.works || []).reduce((workTotal: number, work: WorkItem) => {
				const calculatedTime = parseFloat(work.calculatedTime as unknown as string) || 0
				return workTotal + calculatedTime
			}, 0)
		
			const additionalQuantity = product.productTypeQuoteId === EstimateContentType.VariousTimes || product.productTypeQuoteId === EstimateContentType.LaborTime
				? product.quantity
				: 0
		
			return total + worksTimeTotal + additionalQuantity
		}, 0)
	}

	/**
	 * Calculates the total price for parts.
	 * @param {Product[]} articles - Array of product objects.
	 * @param {boolean} includeDiscount - Flag indicating whether to include discount in the calculation.
	 * @returns {number} The calculated total price.
	 */
	const calculateTotalPartsHt = (articles?: Product[], includeDiscount?: boolean): number => {
		if(!articles) return 0

		return articles
		.filter(product => product.productTypeQuoteId !== EstimateContentType.VariousTimes && product.productTypeQuoteId !== EstimateContentType.Plan)
		.reduce((total: number, product: Product) => {
			const price = product.price.unitPriceBeforePromoHT * product.quantity * (product.conditionnement ?? 1)
			const discountedPrice = price * (includeDiscount ? (1 - (product.price.discount || 0) / 100) : 1)
			return total + discountedPrice
		}, 0)
	}

	/**
	 * Calculates the total price for products of type "plan".
	 * @param {Product[]} articles - Array of product objects.
	 * @param {boolean} includeDiscount - Flag indicating whether to include discount in the calculation.
	 * @returns {number} The calculated total price for "plan" products.
	 */
	const calculateTotalDiversHt = (articles?: Product[], includeDiscount?: boolean): number => {
		if(!articles) return 0

		return articles
		.filter(product => product.productTypeQuoteId === EstimateContentType.Plan)
		.reduce((total: number, product: Product) => {
			const basePrice = product.price.unitPriceBeforePromoHT * product.quantity * (product.conditionnement ?? 1)
			const discountedPrice = basePrice * (includeDiscount ? (1 - (product.price.discount || 0) / 100) : 1 )
			return total + discountedPrice
		}, 0)
	}

	/**
	 * Adds VAT (Value Added Tax) to the given price.
	 * @param {number} price - The initial price before VAT.
	 * @param {number} [vat=20] - The VAT percentage to apply (default is 20%).
	 * @returns {number} The price including VAT.
	 */
	const addVat = (price: number, vat: number = 20): number => {
		return price * (1 + vat / 100)
	}
	
	/**
	 * Calculates the promotion percentage based on the initial and reduced price.
	 * @param {number} initialPrice - The original price of the product.
	 * @param {number} reducedPrice - The discounted price of the product.
	 * @returns {number} The promotion percentage.
	 */
	const calculatePromoPercentage = (initialPrice: number, reducedPrice: number): number => {
		const promo = initialPrice - reducedPrice
		return (promo / initialPrice) * 100
	}

	/**
	 * Calculates the total time for products based on a specific rate type.
	 * This includes both custom time (from the products) and work time.
	 * 
	 * @param {Product[]} articles - Array of product objects.
	 * @param {number} rateTypeId - The id of rate to filter by.
	 * @returns {number} The total calculated time based on the rate type.
	 */
	const calculateTotalTimeByRate = (articles: Product[], rateTypeId: number): number => {
		if(!articles) return 0

		const totalCustomTime = articles 
			.filter(product => product.rate && product.rate.idType === rateTypeId)
			.reduce((total, product) => total + product.quantity, 0); 
	
		const totalTime = articles
			.flatMap(product => product.works ?? [])
			.filter(work => work.rate?.idType === rateTypeId)
			.reduce((total, work) => total + (work.calculatedTime ?? 0), 0);
	
		return totalTime + totalCustomTime
	}
	
	/**
	 * Calculates the total price (HT) for products and work time based on a specific rate type.
	 * This includes both the custom time price (from the products) and the work time price.
	 * 
	 * @param {Product[]} articles - Array of product objects.
	 * @param {number} rateTypeId - The id of rate to filter by.
	 * @returns {number} The total calculated price HT based on the rate type.
	 */
	const calculateTotalTimeByRateHt = (articles: Product[], rateTypeId: number): number => {	
		if(!articles) return 0
		const totalCustomTimePriceHt = articles 
			.filter(product => product.rate && product.rate.idType === rateTypeId)
			.reduce((total, product) => total + ((product.rate?.value ?? 0) * product.quantity), 0);
	
		const totalRatePriceHt = articles
			.flatMap(product => product.works ?? [])
			.filter(work => work.rate?.idType === rateTypeId && work.rate && work.calculatedTime)
			.reduce((total, work) => total + ((work.rate?.value ?? 0) * (work.calculatedTime ?? 0)), 0)
	
		return totalCustomTimePriceHt + totalRatePriceHt
	}

	const productIndex = (storedQuote: Quote, currentProduct: Product) => {
		return storedQuote.articles.findIndex(product => product.uniqueReference === currentProduct.uniqueReference);
	}

	/**
	 * Adds a product to the stored quote. If the product is already present, it updates the quantity.
	 * Otherwise, it adds the product with default quote type and tax values.
	 *
	 * @param {Product} productToAdd - The product to be added to the quote.
	 * @param {EstimateContentType} [productTypeQuoteId=1] productTypeQuoteId - The Estimate Content Type
	 * @returns {Promise<void>} A promise that resolves once the product is added or updated in the quote.
	 */
	const addToQuote = async (productToAdd: Product, productTypeQuoteId: EstimateContentType = EstimateContentType.Product): Promise<void> => {
		const defaultProductTaxeQuoteId = 1

		productToAdd.productTypeQuoteId = productTypeQuoteId
		productToAdd.productTaxQuoteId = defaultProductTaxeQuoteId

		if (!quote.value.articles) quote.value.articles = reactive(<Product[]>[])
		const currentProductIndex = productIndex(quote.value, productToAdd)
	  
		if (currentProductIndex !== -1) {
			//@ts-ignore
		  	quote.value.articles[currentProductIndex].quantity += productToAdd.quantity
		} else {
			quote.value.articles.push(productToAdd)
		}
	}

	/**
	 * Updates the vehicle information in the stored quote within the session storage.
	 * This function modifies the `vehicle` property of the quote with the provided vehicle details, such as KType, name, license plate, and kilometers.
	 * 
	 * @param {number} kType - The vehicle's KType, used for vehicle identification.
	 * @param {string} [name] - The optional name or model of the vehicle.
	 * @param {string} [licencePlate] - The optional license plate of the vehicle.
	 * @param {number} [kilometers] - The optional number of kilometers/mileage of the vehicle.
	 * 
	 * @returns {void} This function does not return a value.
	 */
	const updateVehicleQuote = (kType: number, name?: string, licencePlate?: string, kilometers?: number): void => {
		if(!quote.value.vehicle) quote.value.vehicle = {}
		quote.value.vehicle.carCode = kType
		if(name) quote.value.vehicle.vehicle = name
		if(kilometers) quote.value.vehicle.kilometers = kilometers
		if(licencePlate) quote.value.vehicle.licensePlate = licencePlate
	}

	/**
	 * Updates an existing product in the stored quote if it is already present.
	 * This function locates the product's index in the `quote` object and updates it with the provided `productToUpdate`.
	 *
	 * @param {Product} productToUpdate - The product object containing updated details, which will replace the existing product in the quote.
	 *
	 * @returns {void} This function does not return a value.
	 */
	const updateProduct = (productToUpdate: Product): void => {
		const currentProductIndex = productIndex(quote.value, productToUpdate)

		if (currentProductIndex !== -1) {
			//@ts-ignore
			quote.value.articles[currentProductIndex] = productToUpdate
		}
	}

	return {
		calculateTotalWorkHt,
		calculateTotalTime,
		calculateTotalPartsHt,
		calculateTotalDiversHt,
		addVat,
		calculatePromoPercentage,
		calculateTotalTimeByRate,
		calculateTotalTimeByRateHt,
		addToQuote,
		updateVehicleQuote,
		quote,
		resetQuote,
		updateProduct,
		productIndex
	};
};
export const useQuote = createSharedComposable(_useQuote)